import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

class Lobby extends Component {
  static propTypes = {
    addSession: PropTypes.func.isRequired,
    error: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    sessions: PropTypes.object.isRequired,
    subscribeToSessions: PropTypes.func.isRequired
  }

  state = {
    isPrivate: false,
    name: ''
  }

  componentDidMount() {
    const { subscribeToSessions } = this.props
    this.unsubscribeFromSessions = subscribeToSessions()
  }

  componentWillUnmount() {
    this.unsubscribeFromSessions()
  }

  render() {
    const { addSession, history, sessions } = this.props
    const { isPrivate, name } = this.state
    return (
      <Fragment>
        <h4>Create a lobby</h4>
        <form
          onSubmit={event => {
            const { isPrivate, name } = this.state
            event.preventDefault()
            addSession(name, isPrivate).then(slug => {
              history.push(`/s/${slug}`)
            })
          }}
        >
          <input
            type="text"
            value={name}
            onChange={event => {
              this.setState({
                name: event.target.value
              })
            }}
            placeholder="Lobby Name"
          />
          <br />
          <label>
            <input
              type="checkbox"
              checked={isPrivate}
              onChange={() =>
                this.setState(state => ({ isPrivate: !state.isPrivate }))
              }
            />
            Private
          </label>
          <br />
          <button className="button" type="submit" disabled={name.length === 0}>
            Create/Join Lobby
          </button>
        </form>
        <h4>Join a lobby</h4>
        <div>
          {Object.keys(sessions).map(sessionId => (
            <div key={sessionId}>
              <Link to={`/s/${sessionId}`}>{sessions[sessionId].name}</Link>
            </div>
          ))}
        </div>
      </Fragment>
    )
  }
}

export default Lobby
