const initialState = {
  uid: null,
  error: null,
  isLoading: false
}

function sessions(state = initialState, action) {
  switch (action.type) {
    case 'AUTH_REQUEST':
      return Object.assign({}, state, {
        error: initialState.error,
        isLoading: true
      })
    case 'AUTH_SUCCESS':
      return Object.assign({}, state, {
        uid: action.uid,
        isLoading: false
      })
    case 'AUTH_FAILURE':
      return Object.assign({}, state, {
        error: 'Unable to authenticate.',
        isLoading: false
      })
    default:
      return state
  }
}

export default sessions
