import { database } from '../firebase'
import slug from 'slug'

export const SESSIONS_REQUEST = 'SESSIONS_REQUEST'
export const SESSIONS_SUCCESS = 'SESSIONS_SUCCESS'
export const SESSIONS_FAILURE = 'SESSIONS_FAILURE'

export const sessionsRequest = () => ({ type: SESSIONS_REQUEST })

export const sessionsSuccess = sessions => ({
  type: SESSIONS_SUCCESS,
  sessions
})

export const sessionsFailure = () => ({ type: SESSIONS_FAILURE })

export const subscribeToSessions = () => dispatch => {
  dispatch(sessionsRequest())
  const publicSessionsRef = database
    .ref('/sessions')
    .orderByChild('isPrivate')
    .equalTo(false)
  const watchSessions = publicSessionsRef.on('value', snapshot => {
    dispatch(sessionsSuccess(snapshot.val() || {}))
  })
  return () => {
    publicSessionsRef.off('value', watchSessions)
  }
}

export const addSession = (name, isPrivate = true) => dispatch => {
  const sessionsRef = database.ref('/sessions')
  return new Promise(resolve => {
    const id = slug(name, { lower: true })
    sessionsRef.child(id).once('value', snapshot => {
      if (!snapshot.val()) {
        sessionsRef.update(
          { [id]: { createdAt: new Date().valueOf(), isPrivate, name } },
          () => {
            resolve(id)
          }
        )
      } else {
        resolve(id)
      }
    })
  })
}

// export const joinSession = id => dispatch => {
//   const sessionsRef = database.ref('/sessions');
// };
