import { connect } from 'react-redux'
import { addSession, subscribeToSessions } from '../actions/sessions'
import requireAuth from '../hoc/requireAuth'
import Lobby from '../components/Lobby'

const mapStateToProps = state => ({
  error: state.sessions.error,
  isLoading: state.sessions.isLoading,
  sessions: state.sessions.entities
})

const mapDispatchToProps = dispatch => ({
  addSession: (name, isPrivate) => dispatch(addSession(name, isPrivate)),
  subscribeToSessions: () => dispatch(subscribeToSessions())
})

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Lobby)
)
