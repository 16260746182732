import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { authenticate } from '../actions/auth'

const requireAuth = WrappedComponent => {
  class Auth extends Component {
    static propTypes = {
      authenticate: PropTypes.func.isRequired,
      error: PropTypes.string,
      isLoading: PropTypes.bool.isRequired,
      uid: PropTypes.string
    }

    componentDidMount() {
      const { authenticate, uid } = this.props
      if (!uid) {
        authenticate()
      }
    }

    render() {
      const { authenticate, error, isLoading, uid, ...otherProps } = this.props
      if (error) {
        return 'unable to authenticate...'
      } else if (!uid || isLoading) {
        return 'logging in...'
      } else {
        return <WrappedComponent {...otherProps} />
      }
    }
  }

  const mapStateToProps = state => ({
    error: state.auth.error,
    isLoading: state.auth.isLoading,
    uid: state.auth.uid
  })

  const mapDispatchToProps = dispatch => ({
    authenticate: () => dispatch(authenticate())
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Auth)
}

export default requireAuth
