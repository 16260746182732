import { combineReducers } from 'redux'
import auth from './auth'
import sessions from './sessions'

const rootReducer = combineReducers({
  auth,
  sessions
})

export default rootReducer
