import { auth } from '../firebase'

export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'

const authRequest = () => ({
  type: AUTH_REQUEST
})

const authSuccess = uid => ({
  type: AUTH_SUCCESS,
  uid
})

const authFailure = () => ({
  type: AUTH_FAILURE
})

export const authenticate = () => dispatch => {
  dispatch(authRequest())
  return auth
    .signInAnonymously()
    .then(user => {
      dispatch(authSuccess(user.user.uid))
    })
    .catch(error => {
      dispatch(authFailure())
      console.error(error)
    })
}
