import React, { Component } from 'react'
import requireAuth from '../hoc/requireAuth'

class Game extends Component {
  render() {
    const { match } = this.props
    return <div>{match.params.sessionId}</div>
  }
}

export default requireAuth(Game)
