import {
  SESSIONS_REQUEST,
  SESSIONS_SUCCESS,
  SESSIONS_FAILURE
} from '../actions/sessions'

const initialState = {
  entities: {},
  error: null,
  isLoading: false
}

function sessions(state = initialState, action) {
  switch (action.type) {
    case SESSIONS_REQUEST:
      return { ...state, error: initialState.error, isLoading: true }
    case SESSIONS_SUCCESS:
      return { ...state, entities: action.sessions, isLoading: false }
    case SESSIONS_FAILURE:
      return {
        ...state,
        error: 'An error occured while retrieving sessions',
        isLoading: false
      }
    default:
      return state
  }
}

export default sessions
